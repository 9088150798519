import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public static SkipAllInterceptorHandling: string = 'x-skip-all-interceptor-handling';
  public static SkipInterceptorHandling: string = 'x-skip-interceptor-handling';
  public static SkipRateLimitInterceptorHandling: string = 'x-skip-rate-limit-interceptor-handling';
  public static ErrorMessageTargetKey: string = 'x-error-message-target-key';

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  /**
   * Intercept error request
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (req.headers.get(ErrorInterceptor.SkipAllInterceptorHandling)) {
          return next.handle(req);
        }

        let skipRateLimitInterceptorHandling = false;
        if (req.headers.get(ErrorInterceptor.SkipRateLimitInterceptorHandling)) {
          skipRateLimitInterceptorHandling = true;
        }

        const error = err?.error?.errorCode || 'F0408';
        const traceId = err.headers.get('x-trace-id') || '';

        if (err.status >= 500) {
          this.router.navigate(['error', error, traceId]);
          return next.handle(req);
        }
        if (err.status === 403) {
          this.router.navigate(['error', error]);
          return next.handle(req);
        }

        if (err.status === 429) {
          if (skipRateLimitInterceptorHandling) {
            return next.handle(req);
          }

          this.router.navigate(['error', error]);
          return next.handle(req);
        }

        if (req.headers.get(ErrorInterceptor.SkipInterceptorHandling)) {
          return next.handle(req);
        }

        let errorMessageTargetKey: string | undefined;
        if (req.headers.get(ErrorInterceptor.ErrorMessageTargetKey)) {
          errorMessageTargetKey = req.headers.get(ErrorInterceptor.ErrorMessageTargetKey)!;
        }

        this.notificationService.showError(
          this.translateService.instant('errors.' + error + '.title') || 'Error',
          this.translateService.instant('errors.' + error + '.description') || 'Something went wrong.',
          errorMessageTargetKey,
        );

        return _throw(err);
      }),
    );
  }
}
