<div class="header">
  <nav class="navbar navbar-expand-xl navbar-dark">
    <div class="container container-fluid">
      <!-- Logo -->
      <a class="navbar-brand" routerLink="home">
        <img class="logo" ngSrc="/assets/img/myarvato.svg" alt="MyArvato Logo" height="46" width="175" />
      </a>

      @if (authorizationService.isLoggedIn()) {
        <!-- Menu toggle -->
        <button
          class="navbar-toggler m-1"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".collapsableNavbar"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse flex-start w-100 collapsableNavbar">
          <ul class="navbar-nav">
            <!-- Navigation Items -->
            <li class="nav-item">
              <a class="nav-link" aria-current="page" routerLink="home" routerLinkActive="active" ariaCurrentWhenActive="page">{{
                "core.header.home" | translate
              }}</a>
            </li>

            @if (authorizationService.hasModule(["documents"])) {
              <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="documents" routerLinkActive="active" ariaCurrentWhenActive="page">{{
                  "core.header.documents" | translate
                }}</a>
              </li>
            }

            @if (authorizationService.hasModule(["order360"])) {
              <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="order" routerLinkActive="active" ariaCurrentWhenActive="page">{{
                  "core.header.order-360" | translate
                }}</a>
              </li>
            }

            @if (authorizationService.hasModule(["transport"])) {
              <li class="nav-item">
                <a class="nav-link" aria-current="page" routerLink="transport" routerLinkActive="active" ariaCurrentWhenActive="page">{{
                  "core.header.transport" | translate
                }}</a>
              </li>
            }

            @if (authorizationService.hasModule(["report"])) {
              <li class="nav-item">
                <a class="nav-link" aria-current="page" [routerLink]="['reports']" routerLinkActive="active" ariaCurrentWhenActive="page">{{
                  "core.header.report" | translate
                }}</a>
              </li>
            }

            @if (isCxcUser()) {
              <li class="nav-item dropdown">
                <button
                  class="btn icon-link nav-link"
                  data-bs-toggle="dropdown"
                  aria-current="page"
                  [ngClass]="{ active: rl1Ref.isActive || rl2Ref.isActive || rl3Ref.isActive || rl4Ref.isActive || rl5Ref.isActive }"
                >
                  {{ "core.header.cxc" | translate }}
                  <i class="pi pi-angle-down link--icon"></i>
                </button>

                <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="userDropdown">
                  <li [ngClass]="{ 'd-none': !authorizationService.hasModule(['cxc']) }">
                    <button
                      class="dropdown-item p-2"
                      [routerLink]="['cxc', 'shipments']"
                      routerLinkActive="active"
                      ariaCurrentWhenActive="page"
                      #rl1Ref="routerLinkActive"
                    >
                      {{ "core.header.cxc-shipment" | translate }}
                    </button>
                  </li>
                  <li [ngClass]="{ 'd-none': !authorizationService.hasModule(['cxcAnalyticsTc']) }">
                    <button
                      class="dropdown-item p-2"
                      [routerLink]="['cxc', 'insights', 'tc']"
                      routerLinkActive="active"
                      ariaCurrentWhenActive="page"
                      #rl2Ref="routerLinkActive"
                    >
                      {{ "core.header.cxc-tc" | translate }}
                    </button>
                  </li>
                  <li [ngClass]="{ 'd-none': !authorizationService.hasModule(['cxcExplorer']) }">
                    <button
                      class="dropdown-item p-2"
                      [routerLink]="['cxc', 'explorer', 'orc']"
                      routerLinkActive="active"
                      ariaCurrentWhenActive="page"
                      #rl3Ref="routerLinkActive"
                    >
                      {{ "core.header.cxc-explorer-orc" | translate }}
                    </button>
                  </li>
                  <li [ngClass]="{ 'd-none': !authorizationService.hasModule(['cxcAnalyticsOrc']) }">
                    <button
                      class="dropdown-item p-2"
                      [routerLink]="['cxc', 'insights', 'orc']"
                      routerLinkActive="active"
                      ariaCurrentWhenActive="page"
                      #rl4Ref="routerLinkActive"
                    >
                      {{ "core.header.cxc-orc" | translate }}
                    </button>
                  </li>
                  <li [ngClass]="{ 'd-none': !authorizationService.hasModule(['cxcEdd']) }">
                    <button
                      class="dropdown-item p-2"
                      [routerLink]="['cxc', 'edd']"
                      routerLinkActive="active"
                      ariaCurrentWhenActive="page"
                      #rl5Ref="routerLinkActive"
                    >
                      {{ "core.header.cxc-edd" | translate }}
                    </button>
                  </li>
                </ul>
              </li>
            } @else if (authorizationService.hasPromoModule("cxc")) {
              <li class="nav-item">
                <a class="nav-link" aria-current="page" [routerLink]="['promo/cxc']" routerLinkActive="active" ariaCurrentWhenActive="page">
                  {{ "core.header.cxc" | translate }}
                </a>
              </li>
            }
          </ul>
        </div>

        <!-- Navigation Items -->
        <div class="collapse navbar-collapse flex-end collapsableNavbar">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <button class="btn icon-link" data-bs-toggle="dropdown" aria-expanded="false">
                {{ selectedLanguage().language }}
                <i class="pi pi-angle-down link--icon"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="languageDropdown">
                @for (item of languages(); track $index) {
                  <li class="p-2 mb-0 dropdown-item">
                    <button (click)="setLanguage(item.code)" class="dropdown-item">{{ item.language }}</button>
                  </li>
                }
              </ul>
            </li>

            <li class="nav-item dropdown">
              @if (hasMultipleClients) {
                <button class="btn icon-link text-nowrap" data-bs-toggle="dropdown" aria-current="page">
                  {{ client?.client }}
                  <i class="pi pi-angle-down link--icon"></i>
                </button>

                <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="clientDropdown">
                  @for (item of clients; track $index) {
                    <button class="dropdown-item p-2" [class.active]="item.client == client?.client" (click)="switchClient(item)">
                      {{ item.client }}
                    </button>
                  }
                </ul>
              } @else if (hasOneClients) {
                <span class="one-client text-nowrap" aria-current="page">
                  {{ client?.client }}
                </span>
              }
            </li>

            <li class="nav-item dropdown">
              <button class="btn icon-link text-nowrap" data-bs-toggle="dropdown" aria-current="page">
                {{ userName }}
                <i class="pi pi-angle-down link--icon"></i>
              </button>

              <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="userDropdown">
                <li>
                  <button class="dropdown-item p-2" [routerLink]="['setting']">
                    <em class="pi pi-cog me-1"></em>{{ "core.header.settings" | translate }}
                  </button>
                </li>
                <li>
                  <button class="dropdown-item p-2" [routerLink]="['logout']">
                    <em class="pi pi-sign-out me-1"></em> {{ "core.header.logout" | translate }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      }
    </div>
  </nav>
</div>
