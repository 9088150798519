import { LocalizationService } from 'src/app/core/localization/localization-service.service';
import { ApplicationConfig, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, TitleStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { ResponseInterceptor } from './core/interceptors/response.interceptor';
import { XSelectedClientHint } from './core/interceptors/x-selectedClientHint.interceptor';
import { routes } from './app-routing.module';
import { MsalGuard } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { PrimeNG, providePrimeNG } from 'primeng/config';
import { AuthGuard, ModuleGuard, RoleGuard } from '@myarvato/shared/guards';
import { AuthModule } from './core/auth/auth.module';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { DateFormatUtility } from './modules/cxc/cxc.insights/shared/utilities/date-format.utility';
import { MyArvatoEnvironment } from '@myarvato/shared/models';
import { TemplatePageTitleStrategy } from './core/services/template-page-title-strategy.service';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { MyArvatoTheme } from './core/theme';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);

registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    TranslateService,
    TranslateStore,
    AuthGuard,
    RoleGuard,
    ModuleGuard,
    MsalGuard,
    MessageService,
    provideHttpClient(withInterceptorsFromDi()),

    provideAnimations(),
    providePrimeNG({
      theme: {
        preset: MyArvatoTheme,
        options: {
          darkModeSelector: 'none',
        },
      },
      ripple: true,
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient],
        },
      }),
      MatomoModule.forRoot({
        disabled: environment.tracking.disabled,
        trackerUrl: environment.tracking.trackerUrl,
        scriptUrl: environment.tracking.scriptUrl,
        siteId: environment.tracking.siteId,
        acceptDoNotTrack: environment.tracking.acceptDoNotTrack,
        enableLinkTracking: environment.tracking.enableLinkTracking,
      }),
      MatomoRouterModule.forRoot({
        exclude: [],
      }),
      AuthModule,
    ),
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    provideAppInitializer(() => {
      const initializerFn = appInitializerFactory(inject(TranslateService), inject(LocalizationService));
      return initializerFn();
    }),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XSelectedClientHint,
      multi: true,
    },

    { provide: LOCALE_ID, useExisting: LocalizationService },
    { provide: MyArvatoEnvironment, useValue: environment },
  ],
};

export function appInitializePrimeNg(primengConfig: PrimeNG, service: LocalizationService) {
  return async () => {
    let locale = service.getLocale();
    let localeFormat = DateFormatUtility.getDateFormatCode(locale);

    primengConfig.setTranslation({ dateFormat: localeFormat });
  };
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/api/lang/', '.json');
}
export function appInitializerFactory(translate: TranslateService, service: LocalizationService) {
  return () => {
    service.getLocalSubscribe().subscribe();
    let language = localStorage.getItem('language');

    if (!language) {
      language = 'en';
      localStorage.setItem('language', language);
    }

    translate.setDefaultLang(language);
    return translate.use(language).toPromise();
  };
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: httpTranslateLoader,
    deps: [HttpClient, Router],
  },
});
