{
  "name": "arvato-myarvato-webportal-frontend",
  "version": "1.19.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test-headless": "ng test --watch=false --browsers=ChromeHeadless --reporters=junit --code-coverage",
    "test-headless-sonar": "ng test --watch=false --browsers=ChromeHeadless --reporters=sonarqube --code-coverage",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.6",
    "@angular/cdk": "^19.1.4",
    "@angular/common": "^19.1.6",
    "@angular/compiler": "^19.1.6",
    "@angular/core": "^19.1.6",
    "@angular/forms": "^19.1.6",
    "@angular/platform-browser": "^19.1.6",
    "@angular/platform-browser-dynamic": "^19.1.6",
    "@angular/router": "^19.1.6",
    "@azure/msal-angular": "^3.0.23",
    "@azure/msal-browser": "^3.23.0",
    "@ng-bootstrap/ng-bootstrap": "^18.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@popperjs/core": "^2.11.8",
    "@primeng/themes": "^19.0.10",
    "primeng": "^19.0.10",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.7",
    "dayjs": "^1.11.13",
    "flag-icons": "^7.3.2",
    "i18n-iso-countries": "^7.13.0",
    "ngx-matomo-client": "^7.0.1",
    "powerbi-client": "^2.23.1",
    "primeicons": "^7.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "uuid": "^11.0.5",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.7",
    "@angular/cli": "~19.1.7",
    "@angular/compiler-cli": "^19.1.6",
    "@angular/localize": "^19.1.6",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.20.0",
    "@types/jasmine": "~5.1.6",
    "@types/uuid": "^10.0.0",
    "angular-eslint": "^19.1.0",
    "eslint": "^9.20.1",
    "eslint-config-prettier": "^10.0.1",
    "eslint-plugin-prettier": "^5.2.3",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-sonarqube-reporter": "^1.4.0",
    "ng-packagr": "^19.1.2",
    "prettier": "^3.5.1",
    "prettier-eslint": "^16.3.0",
    "sonar-scanner": "^3.1.0",
    "typescript": "^5.7.3",
    "typescript-eslint": "8.24.0"
  }
}
