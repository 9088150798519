import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';
import { ContactCardComponent, ContactComponent } from '@myarvato/shared/contact';
import { TitleComponent } from '@myarvato/shared/title';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    imports: [TitleComponent, ContactComponent, ContactCardComponent, UpperCasePipe, TranslateModule]
})
export class UnauthorizedComponent {
  router = inject(Router);
  title: string = 'Access denied';
  description: string = 'Unauthorized user';

  countdown: number = 30;

  ngOnInit() {
    this.redirect();
  }

  redirect() {
    const timer = interval(1000);
    const subscribe = timer.subscribe((n) => {
      if (this.countdown == 0) {
        subscribe.unsubscribe();

        this.router.navigate(['logout']);
        window.location.reload();
        return;
      }

      this.countdown--;
    });
  }
}
