import { Component } from '@angular/core';
import { LoadingService } from './loading.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    imports: [NgIf]
})
export class SpinnerComponent {
  constructor(public loadingService: LoadingService) {}
}
